import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import Textarea from "primevue/textarea";
import Password from "primevue/password";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import RadioButton from "primevue/radiobutton";
import Toolbar from "primevue/toolbar";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "primevue/skeleton";
import Tooltip from "primevue/tooltip";
import Sidebar from "primevue/sidebar";
import moment from "moment";
import NoAutoComplete from "vue-no-autocomplete";

import idsrvAuth from "./config/idsrvAuth";
import BadgeDirective from 'primevue/badgedirective';
//import Toast from 'primevue/toast';
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(ConfirmationService);
app.use(ToastService);
app.use(NoAutoComplete);

/*
app.use(VueGapi, {
    apiKey: 'AIzaSyASMBcPBt3EhJgDmk7UsS8nLPQaKZf5KXM',
    clientId: '82795536894-1ihiebapkpvk676i4jnhdbnpimb7340v.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest', 'https://www.googleapis.com/discovery/v1/apisyoutube/v3/rest'],
    scope: 'https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/youtube',
  });*/

app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("Textarea", Textarea);
app.component("Password", Password);
app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("RadioButton", RadioButton);
app.component("InputSwitch", InputSwitch);
app.component("Toolbar", Toolbar);
app.component("Dialog", Dialog);
app.component("Sidebar", Sidebar);
app.component("Calendar", Calendar);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ConfirmPopup", ConfirmPopup);
app.component("OverlayPanel", OverlayPanel);
app.component("Skeleton", Skeleton);
//app.component("Toast", Toast);
app.directive("tooltip", Tooltip);
app.directive('badge', BadgeDirective);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
//app.config.globalProperties.$messaging = firebaseMessaging
moment.locale('es')
app.config.globalProperties.$filters = {
  formatDate(value) {
    const duration = moment(value).format("DD/MM/yyyy");
    return `${duration}`;
  },
};


app.use(PrimeVue, {
  ripple: true,
  locale: {
    accept: "Aceptar",
    reject: "Rechazar",
    firstDayOfWeek: 1,
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct", 
      "Nov",
      "Dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Wk",
  },
});
app.use(i18n);
idsrvAuth.startup().then((ok) => {
  if (ok) {
    app.config.globalProperties.$oidc = idsrvAuth;
    app.mount("#app");
  } 
});
//app.mount("#app");
