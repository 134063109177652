import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import SucursalModule from "@/store/modules/SucursalModule";
import ComunaModule from "@/store/modules/ComunaModule";
import ReservaModule from "@/store/modules/ReservaModule";
import DisponibilidadModule from "@/store/modules/DisponibilidadModule";
import NotificacionModule from "@/store/modules/NotificacionModule";
import TrazabilidadModule from "@/store/modules/TrazabilidadModule";
import ViajeCompartidoModule from "@/store/modules/ViajeCompartidoModule";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    SucursalModule,
    ComunaModule,
    ReservaModule,
    DisponibilidadModule,
    TrazabilidadModule,
    ViajeCompartidoModule,
    NotificacionModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
});

export default store;
