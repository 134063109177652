const ID_TOKEN_KEY = "id_token" as string;
const ID_TOKEN_REFRESH = "id_token_refresh" as string;
const ID_USERNAME_KEY = "id_username" as string;
const ID_PASSWORD_KEY = "id_password" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenRefresh = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_REFRESH);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveTokenRefresh = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_REFRESH, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_REFRESH);
  window.localStorage.removeItem(ID_USERNAME_KEY);
  window.localStorage.removeItem(ID_PASSWORD_KEY);
};



/** 
 * Informacion del login para persistir
*/
export const getUsername = (): string | null => {
  return window.localStorage.getItem(ID_USERNAME_KEY);
};

export const getPassword = (): string | null => {
  return window.localStorage.getItem(ID_PASSWORD_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveUsername = (token: string): void => {
  window.localStorage.setItem(ID_USERNAME_KEY, token);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const savePassword = (token: string): void => {
  window.localStorage.setItem(ID_PASSWORD_KEY, token);
};



export default {
  getToken,
  getTokenRefresh,
  saveToken,
  saveTokenRefresh,

  getUsername,
  getPassword,
  saveUsername,
  savePassword,

  destroyToken,
};
