
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { provide } from 'vue'
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
//import firebaseMessaging from './config/firebase'
//import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default defineComponent({
  /*provide: {
    messaging: firebaseMessaging
  },*/
  components:{Toast},
  name: "app",
  setup() {
    const store = useStore();
    //console.log('firebaseMessaging ' + firebaseMessaging);
    const toast = useToast();
    //provide('messaging', firebaseMessaging);
    const notificacion = ref();
    const token = ref();
    /*onMessage(firebaseMessaging, (payload) => {
      console.log('Message received. ', payload);
      notificacion.value = payload;
      toast.add({severity: 'info', summary: notificacion.value.data.title, detail: notificacion.value.data.body});
    });*/


    onMounted(() => {
      /*window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent the mini-infobar from appearing on mobile.
        event.preventDefault();
        console.log("👍", "beforeinstallprompt", event);
        // Stash the event so it can be triggered later.
        //window.deferredPrompt = event;
        // Remove the 'hidden' class from the install button container.
        //divInstall.classList.toggle('hidden', false);
      });*/
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
    const showSticky = () => {
        toast.add({severity: 'info', summary: 'Sticky Message', detail: 'Message Content'});
    }
    return {showSticky};
  },
});
