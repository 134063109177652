enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  DELETE_USER = "deleteUser",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  VERIFY_TOKEN_PASSWORD = "verifyTokenPassword",
  UPDATE_PASSWORD = "updatePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  GET_RESERVAS = "getReservas",
  GET_RESERVAS_POR_ACEPTAR = "getReservasPorAceptar",
  GET_RESERVAS_CERRADAS = "getReservasCerradas",
  GET_RESERVA = "getReserva",
  GET_RESERVA_PROXIMA = "getReservaProxima",
  INS_RESERVA = "createReserva",
  INS_RESERVA_COMPARTIDA = "createReservaCompartida",
  UPD_RESERVA_VEHICULO = "updateReservaVehiculo",
  UPD_RESERVA_PERSONAL = "updateReservaPersonal",
  UPD_RESERVA_CONFIRMAR = "updateReservaConfirmar",
  UPD_RESERVA_ACEPTAR = "updateReservaAceptar",
  UPD_RESERVA = "updateReserva",
  DEL_RESERVA = "deleteReserva",


  GET_VIAJES_COMPARTIDOS = "getViajesCompartidos", 
  GET_VIAJES_SOLICITADOS = "getViajesSolicitados",
  GET_CUPOS_VIAJES_COMPARTIDOS= "getCuposViajesCompartidos",
  GET_VIAJE_COMPARTIDO = "getViajeCompartido",
  INS_VIAJE_COMPARTIDO = "createViajeCompartido",
  UPD_VIAJE_COMPARTIDO_PERSONAL = "updateViajeCompartidoPersonal",
  UPD_VIAJE_COMPARTIDO_CONFIRMAR = "updateViajeCompartidoConfirmar",
  UPD_VIAJE_COMPARTIDO = "updateViajeCompartido",
  UPD_VIAJE_ACEPTAR = "updateViajeAceptar",
  UPD_VIAJE_RECHAZAR = "updateViajeRechazar", 
  DEL_VIAJE_COMPARTIDO = "deleteViajeCompartido",



  GET_TRAZABILIDAD_INICIO = "getTrazabilidadInicio",
  GET_TRAZABILIDAD_TERMINO = "getTrazabilidadTermino",
  GET_TRAZABILIDAD = "getTrazabilidad",
  INS_TRAZABILIDAD = "createTrazabilidad",
  UPD_TRAZABILIDAD = "updateTrazabilidad",
  DEL_TRAZABILIDAD = "deleteTrazabilidad",

  GET_NOTIFICACIONES = "getNotificaciones",
  UPD_NOTIFICACION = "updateNotificacion",
  UPD_NOTIFICACION_ARCHIVAR = "updateNotificacionArchivar",

  GET_SUCURSALES = "getSucursales",
  GET_COMUNAS = "getComunas",

  GET_DISPONIBILIDAD = "getDisponibilidad",
  GET_DISPONIBILIDAD_CERCANA = "getDisponibilidadCercana",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_RESERVA = "setReserva",
  SET_RESERVA_ID = "setReservaId",
  SET_RESERVAS = "setReservas",
  SET_RESERVAS_POR_ACEPTAR = "setReservasPorAceptar",
  SET_SUCURSAL = "setSucursal",
  SET_SUCURSALES = "setSucursales",

  SET_COMUNA = "setComuna",
  SET_COMUNAS = "setComunas",

  SET_TRAZABILIDAD = "setTrazabilidad",
  SET_TRAZABILIDADES = "setTrazabilidades",

  SET_NOTIFICACION = "setNotificacion",
  SET_NOTIFICACIONES = "setNotificaciones",

  SET_VIAJE_COMPARTIDO = "setViajeCompartido",
  SET_VIAJES_COMPARTIDOS = "setViajesCompartidos",

  SET_DISPONIBILIDAD = "setDisponibilidad",
  SET_DISPONIBILIDAD_CERCANA = "setDisponibilidadCercana",

  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_PASS = "setUserPass",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_AUTH_ERROR = "setError",
  SET_RESERVA_ERROR = "setReservaError",
  SET_VIAJE_COMPARTIDO_ERROR = "setViajeCompartidoError",
  SET_TRAZABILIDAD_ERROR = "setTrazabilidadError",
  SET_NOTIFICACION_ERROR = "setNotificacionError",
  SET_SUCURSAL_ERROR = "setSucursalError",
  SET_COMUNA_ERROR = "setComunaError",
  SET_DISPONIBILIDAD_ERROR = "setDisponibilidadError",

  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

export { Actions, Mutations };
