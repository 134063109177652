import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Disponibilidad {
  anio: number;
  color: string;
  marca: string;
  modelo: string;
  patente: string;
  segmento: string;
  cantidad: number;
  fecha?:Date,
  disponible?:boolean;
}

export interface DisponibilidadInfo {
  errors: unknown;
  disponibilidad: Array<Disponibilidad>;
  disponibilidadCercana: Array<Disponibilidad>;
}

@Module
export default class DisponibilidadModule
  extends VuexModule
  implements DisponibilidadInfo
{
  errors = {};
  disponibilidad = [] as Array<Disponibilidad>;
  disponibilidadCercana = [] as Array<Disponibilidad>;

  /**
   * Get all disponibilidad
   * @returns Array<Disponibilidad>
   */
  get allDisponibilidad(): Array<Disponibilidad> {
    return this.disponibilidad;
  }

  /**
   * Get all disponibilidad
   * @returns Array<Disponibilidad>
   */
   get allDisponibilidadCercana(): Array<Disponibilidad> {
    return this.disponibilidadCercana;
  }

  /**
   * Get errors
   * @returns array
   */
  get getDisponibilidadErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_DISPONIBILIDAD_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_DISPONIBILIDAD](disponibilidad) {
    this.disponibilidad = disponibilidad;
  }

  @Mutation
  [Mutations.SET_DISPONIBILIDAD_CERCANA](disponibilidad) {
    this.disponibilidadCercana = disponibilidad;
  }

  @Action
  [Actions.GET_DISPONIBILIDAD](params) {
    return ApiService.post(
      `Disponibilidad/v1/disponibilidadFlota/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISPONIBILIDAD, data.flota);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_DISPONIBILIDAD_ERROR,
          response.data.errors
        );
        throw new Error();
      });
  }

  @Action
  [Actions.GET_DISPONIBILIDAD_CERCANA](params) {
    return ApiService.post(
      `Disponibilidad/v1/disponibilidadCercana/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISPONIBILIDAD_CERCANA, data.disponibilidad);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_DISPONIBILIDAD_ERROR,
          response.data.errors
        );
        throw new Error();
      });
  }
}
