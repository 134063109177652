import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Trazabilidad {
  id: number;
  reservaId: string;
  esInicio: boolean;
  kilometrosActuales: string;
  imagenKilometros: string;
  nivelCombustible: string;
  imagenCombustible: string;
  limpieza: string;
  imagenLimpieza: string;
  cargaCombustible: boolean| undefined;
  cargaDinero: number| undefined;
}

export interface TrazabilidadInfo {
  errors: unknown;
  trazabilidad: Trazabilidad;
  trazabilidades: Array<Trazabilidad>;
}

@Module
export default class TrazabilidadModule extends VuexModule implements TrazabilidadInfo {
  errors = {};
  trazabilidad = {} as Trazabilidad;
  trazabilidades = [] as Array<Trazabilidad>;

  /**
   * Get current trazabilidad object
   * @returns Trazabilidad
   */
  get currentTrazabilidad(): Trazabilidad {
    return this.trazabilidad;
  }

  /**
   * Get all trazabilidades
   * @returns Array<Trazabilidad>
   */
  get allTrazabilidades(): Array<Trazabilidad> {
    return this.trazabilidades;
  }

  /**
   * Get errors
   * @returns array
   */
  get getTrazabilidadErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_TRAZABILIDAD_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_TRAZABILIDAD](trazabilidad) {
    this.trazabilidad = trazabilidad;
  }

  @Mutation
  [Mutations.SET_RESERVAS](trazabilidades) {
    this.trazabilidades = trazabilidades;
  }

  @Action
  [Actions.GET_TRAZABILIDAD_INICIO](id) {
    return ApiService.get(`TrazabilidadReserva/v1/reserva/${id}/inicio`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TRAZABILIDAD_TERMINO](id) {
    return ApiService.get(`TrazabilidadReserva/v1/reserva/${id}/termino`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TRAZABILIDAD](id) {
    return ApiService.get(`TrazabilidadReserva/v1/trazabilidadReserva/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.INS_TRAZABILIDAD](params) {
    return ApiService.postBlob(`TrazabilidadReserva/v1/trazabilidadReserva/`, params.formData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_TRAZABILIDAD](params) {
    return ApiService.patch(
      `TrazabilidadReserva/v1/reserva/trazabilidadReserva/${params.trazabilidadId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
        throw new Error();
      });
  }

  
  @Action
  [Actions.DEL_TRAZABILIDAD](id) {
    return ApiService.delete(`TrazabilidadReserva/v1/trazabilidadReserva/${id}`)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TRAZABILIDAD_ERROR, response.data.errors);
        throw new Error();
      });
  }
}
