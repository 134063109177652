import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Roles } from "@/store/enums/RolesEnums";
import { nextTick } from "vue";
import idsrvAuth from "../config/idsrvAuth";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/Terms.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },

      {
        path: "/accidents",
        name: "accidents",
        component: () => import("@/views/accidents/pages/Declaration.vue"),
      },

      {
        path: "/agenda",
        name: "agenda",
        component: () => import("@/views/agenda/pages/Agenda.vue"),
      },
      {
        path: "/assists",
        name: "assists",
        component: () => import("@/views/assists/pages/Assists.vue"),
      },

      {
        path: "/reservations",
        name: "reservations",
        component: () => import("@/views/reservations/pages/Reservations.vue"),
      },
      {
        path: "reservation/:id?",
        name: "reservation-overview",
        component: () => import("@/views/reservations/pages/Overview.vue"),
      },
      {
        path: "availablefleet",
        name: "available-fleet",
        component: () =>
          import("@/views/reservations/pages/AvailableFleet.vue"),
      },
      {
        path: "personalData",
        name: "reservation-personal",
        component: () => import("@/views/reservations/pages/PersonalData.vue"),
      },
      {
        path: "reservationConfirm",
        name: "reservation-confirm",
        component: () => import("@/views/reservations/pages/Confirm.vue"),
      },
      {
        path: "reservationVoucher",
        name: "reservation-voucher",
        component: () => import("@/views/reservations/pages/Voucher.vue"),
      },
      {
        path: "reservationHistory",
        name: "reservation-history",
        component: () => import("@/views/reservations/pages/History.vue"),
      },
      {
        path: "startTrip/:id",
        name: "start-trip",
        component: () => import("@/views/reservations/pages/StartTrip.vue"),
      },
      {
        path: "endTrip/:id",
        name: "end-trip",
        component: () => import("@/views/reservations/pages/EndTrip.vue"),
      },
      {
        path: "ride",
        name: "ride",
        component: () => import("@/views/reservations/pages/Ride.vue"),
      },
      {
        path: "capture",
        name: "capture",
        component: () => import("@/views/reservations/pages/Captura.vue"),
      },

      {
        path: "travelMe",
        name: "travel-me",
        component: () => import("@/views/reservations/pages/TravelMe.vue"),
      },
      {
        path: "sharedMenu",
        name: "shared-menu",
        component: () => import("@/views/sharing/pages/Menu.vue"),
      },
      {
        path: "shared",
        name: "shared",
        component: () => import("@/views/sharing/pages/Overview.vue"),
      },
      {
        path: "sharedPersonalData",
        name: "shared-reservation-personal",
        component: () => import("@/views/sharing/pages/PersonalData.vue"),
      },
      {
        path: "sharedReservationConfirm",
        name: "shared-reservation-confirm",
        component: () => import("@/views/sharing/pages/Confirm.vue"),
      },
      {
        path: "sharedReservationVoucher",
        name: "shared-reservation-voucher",
        component: () => import("@/views/sharing/pages/Voucher.vue"),
      },
      {
        path: "travelSearch",
        name: "travel-search",
        component: () => import("@/views/reservations/pages/TravelSearch.vue"),
      },

      {
        path: "/shared-request",
        name: "shared-request",
        component: () => import("@/views/sharing/pages/Sharing.vue"),
      },

      {
        path: "/reservations-attentions",
        name: "reservations-attentions",
        component: () => import("@/views/reservations/pages/ReservationsConfirm.vue"),
      },

    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/splash/:token?",
        name: "splash",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Splash.vue"),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        /*meta: {
          authName: idsrvAuth.authName
        },*/
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/token/:token",
        name: "token",
        /*meta: {
          authName: idsrvAuth.authName
        },*/
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Token.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/sign-up-thanks",
        name: "sign-up-thanks",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpThank.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-change/:token",
        name: "password-change",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordChange.vue"
          ),
      },
    ],
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("@/components/page-layouts/Onboarding.vue"),
    children: [
      {
        path: "start",
        name: "onboarding-start",
        component: () => import("@/views/crafted/onboarding/Onboarding.vue"),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: -10 };
      }
    }
  },
  history: createWebHashHistory(),
  //history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  await store.dispatch(Actions.VERIFY_AUTH, to);
  if (!store.getters.isUserAuthenticated) {
    //router.push({ name: "splash" });
  }
  const url = to.meta.alternate as string;
  if (to.meta.alternate) {
    if (Roles.ADMINISTRADOR == store.getters.currentUser.rol) {
      return { name: url };
    }
  }
  // Scroll page to top on every route change
  /*setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);*/
});
idsrvAuth.useRouter(router);
export default router;
