import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface ViajeCompartido {
  id: number;
  clientId: string;
  driverId: string;
  codigoSegmentoVehiculo: string;
  segmentoVehiculo: string;
  marcaVehiculo: string;
  modeloVehiculo: string;
  versionVehiculo: number;
  fechaRetiro: Date;
  horaRetiro: string;
  fechaDevolucion: Date;
  horaDevolucion: string;
  estacionRetiro: string;
  estacionDevolucion: string;
  numeroReserva: string;
  neta: number | undefined;
  iva: number | undefined;
  bruto: number | undefined;
  codigoEstado: string;
  estado: string;
  reservaConfirmada: boolean;
  reservaAnulada: boolean;
  email: string;
  rut: string;
  nombre: string;
  telefono: string;
  esCompartido: boolean;
  cuposTotal: number | undefined;
  cuposDisponibles: number | undefined;
}

export interface ViajeCompartidoInfo {
  errors: unknown;
  viajeCompartido: ViajeCompartido;
  viajesCompartidos: Array<ViajeCompartido>;
}

@Module
export default class ViajeCompartidoModule extends VuexModule implements ViajeCompartidoInfo {
  errors = {};
  viajeCompartido = {} as ViajeCompartido;
  viajesCompartidos = [] as Array<ViajeCompartido>;


  /**
   * Get current viajeCompartido object
   * @returns ViajeCompartido
   */
  get currentViajeCompartido(): ViajeCompartido {
    return this.viajeCompartido;
  }

  /**
   * Get all viajesCompartidos
   * @returns Array<ViajeCompartido>
   */
  get allViajesCompartidos(): Array<ViajeCompartido> {
    return this.viajesCompartidos;
  }

  /**
   * Get errors
   * @returns array
   */
  get getViajeCompartidoErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_VIAJE_COMPARTIDO_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_VIAJE_COMPARTIDO](viajeCompartido) {
    this.viajeCompartido = viajeCompartido;
  }

  @Mutation
  [Mutations.SET_VIAJES_COMPARTIDOS](viajesCompartidos) {
    this.viajesCompartidos = viajesCompartidos;
  }

  @Action
  [Actions.GET_VIAJES_COMPARTIDOS](id) {
    return ApiService.get(`ViajeCompartido/v1/pasajero/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJES_COMPARTIDOS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_VIAJES_SOLICITADOS](id) { 
    this.context.commit(Mutations.SET_RESERVAS, []);
    return ApiService.get(`ViajeCompartido/v1/solicitudes/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CUPOS_VIAJES_COMPARTIDOS](params) {
    return ApiService.post(`ViajeCompartido/v1/reserva/${params.driverId}/${params.clientId}`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_VIAJE_COMPARTIDO](id) {
    return ApiService.get(`ViajeCompartido/v1/viajeCompartido/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.INS_VIAJE_COMPARTIDO](params) {
    return ApiService.post(`ViajeCompartido/v1/viajeCompartido/`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_VIAJE_COMPARTIDO_PERSONAL](params) {
    return ApiService.patch(
      `ViajeCompartido/v1/viajeCompartido/personal/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_VIAJE_ACEPTAR](params) {
    return ApiService.patch(
      `ViajeCompartido/v1/solicitud/${params.viajeId}/aceptar`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }
  
  @Action
  [Actions.UPD_VIAJE_RECHAZAR](params) {
    return ApiService.patch(
      `ViajeCompartido/v1/solicitud/${params.viajeId}/rechazar`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_VIAJE_COMPARTIDO_CONFIRMAR](reservaId) {
    return ApiService.patch(
      `ViajeCompartido/v1/viajeCompartido/confirmar/${reservaId}`,
      reservaId
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_VIAJE_COMPARTIDO](params) {
    return ApiService.put(`ViajeCompartido/v1/viajeCompartido/${params.reservaId}`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.DEL_VIAJE_COMPARTIDO](id) {
    return ApiService.delete(`ViajeCompartido/v1/viajeCompartido/${id}`)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_VIAJE_COMPARTIDO_ERROR, response.data.errors);
        throw new Error();
      });
  }
}
