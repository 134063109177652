import { User } from "oidc-client";
import { createOidcAuth, SignInType, LogLevel } from "./vue-oidc-client";

const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;

const idsrvAuth = createOidcAuth(
  "main",
  SignInType.Window,
  appRootUrl,
  {
    authority: "https://localhost:44395/",
    client_id: "mvc", // 'implicit.shortlived',
    response_type: "code",
    scope: "openid",
    client_secret: "901564A5-E7FE-42CB-B10D-61EF6A8F3654",
    // test use
    prompt: "login",
  },
  console,
  LogLevel.Debug
);

// handle events
idsrvAuth.events.addAccessTokenExpiring(function () {
  // eslint-disable-next-line no-console
  console.log("access token expiring");
});

idsrvAuth.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line no-console
  console.log("access token expired");
});

idsrvAuth.events.addSilentRenewError(function (err: Error) {
  // eslint-disable-next-line no-console
  console.error("silent renew error", err);
});

idsrvAuth.events.addUserLoaded(function (user: User) {
  // eslint-disable-next-line no-console
  console.log("user loaded", user);
});

idsrvAuth.events.addUserUnloaded(function () {
  // eslint-disable-next-line no-console
  console.log("user unloaded");
});

idsrvAuth.events.addUserSignedOut(function () {
  // eslint-disable-next-line no-console
  console.log("user signed out");
});

idsrvAuth.events.addUserSessionChanged(function () {
  // eslint-disable-next-line no-console
  console.log("user session changed");
});

export default idsrvAuth;
