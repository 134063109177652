import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Comuna {
  ComunalId: string;
  comuna: string;
  codigoSucursal: string;
  sucursal: string;
  codigoAdicional: string;
}

export interface ComunaInfo {
  errors: unknown;
  comuna: Comuna;
  comunas: Array<Comuna>;
}

@Module
export default class ComunaModule extends VuexModule implements ComunaInfo {
  errors = {};
  comuna = {} as Comuna;
  comunas = [] as Array<Comuna>;

  /**
   * Get current comunan object
   * @returns Comuna
   */
  get currentComuna(): Comuna {
    return this.comuna;
  }

  /**
   * Get all comunas
   * @returns Array<Comuna>
   */
  get allComunas(): Array<Comuna> {
    return this.comunas;
  }

  /**
   * Get errors
   * @returns array
   */
  get getComunaErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_COMUNA_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_COMUNA](comuna) {
    this.comuna = comuna;
  }

  @Mutation
  [Mutations.SET_COMUNAS](comunas) {
    this.comunas = comunas;
  }
  @Action
  [Actions.GET_COMUNAS]() {
    return ApiService.get(`comuna/v1/comunas`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COMUNAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_COMUNA_ERROR, response.data.errors);
      });
  }
}
